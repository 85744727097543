import React from 'react'
import PropTypes from "prop-types"

const PrivacyPolicy = ({privacyData}) => (
  <div className="privacy-section">
    <h1>Privacy Policy</h1>
    <p>{privacyData.privacyStatement}</p>
  </div>
)

PrivacyPolicy.propTypes = {
  privacyData: PropTypes.object,
}


export default PrivacyPolicy
