import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PrivacyPolicy from '../components/PrivacyPolicy'

export const PrivacyPageTemplate = ({
  privacySection,
}) => {
  
  return (
    <div>
      <PrivacyPolicy privacyData={privacySection}/>
    </div>
  )
}


PrivacyPageTemplate.propTypes = {
  privacySection: PropTypes.object,
}

const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PrivacyPageTemplate
        privacySection={frontmatter.privacySection}
      />
    </Layout>
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PrivacyPage

export const pageQuery = graphql`
  query PrivacyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy" } }) {
      frontmatter {
        privacySection {
          privacyStatement
        }
      }
    }
  }
`
